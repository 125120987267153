.App {
  text-align: center;
}

.submit-container-cs {
  text-align: center;
}
.submit-btn-cs {
  margin-top: 20px;
  background-color: #4766ff;
  color: #fff;
  border: 0px;
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 15px;
}

.fade-in {
  opacity: 1;
  transition: opacity 0.5s ease-in;
}
.fade-out {
  opacity: 0;
  transition: opacity 0.5s ease-out;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.particle-container {
  position: absolute;
  width: 100%; /* Adjust width as needed */
  height: 750px; /* Adjust height as needed */
  background-color: #000; /* Background color of the container */
  overflow: hidden; /* Ensure particles stay within the container */
}

.particle-container canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.main-heading {
  padding: 9em 26em 12em;
  text-align: left;
}
.mt-30 {
  margin-top: 30px;
}

.ml6 {
  position: relative;
  font-weight: 900;
  font-size: 3.3em;
  word-break: keep-all;
  word-wrap: normal;
}

.ml6 .text-wrapper {
  position: relative;
  display: inline-block;
  padding-right: 0.05em;
  padding-bottom: 0.1em;
  overflow: hidden;
}

.ml6 .letter {
  line-height: 1em;
  color: #fff;
}

.press-enter-web {
  text-align: right;
  font-size: 14px;
  color: grey;
  position: relative;
}

.custom-textarea {
  background-color: transparent !important;
  width: 100%; /* Custom width (adjust as needed) */
  height: 150px; /* Adjust height as needed */
  font-weight: bold !important;
  padding: 10px; /* Optional: Add some padding for better appearance */
  border: none !important; /* Remove border */
  color: #fff !important; /* Text color */
  font-size: 23px; /* Font size */
  resize: none; /* Prevent resizing by user */
  line-height: 1.5; /* Adjust line-height for readability */
  overflow: hidden; /* Hide scrollbars */
  position: relative;
}
.custom-textarea::placeholder {
  color: grey; /* Grey placeholder color */
  opacity: 1; /* Ensure placeholder color is fully opaque */
  white-space: pre-line; /* Preserve line breaks */
}

.custom-textarea:focus {
  outline: none; /* Remove default focus outline */
  border-color: #666; /* Change border color on focus */
}

@media only screen and (max-width: 768px) {
  .free-consult-heading {
    padding: 0px !important;
    font-size: 25px !important;
  }
  .main-heading {
    padding: 130px 10px;
    text-align: left; /* This can be adjusted if needed */
  }
  .ml6 {
    position: relative;
    font-weight: 900;
    font-size: 2em;
    word-break: keep-all;
    word-wrap: normal;
  }
  .custom-textarea {
    background-color: transparent !important;
    width: 100%; /* Custom width (adjust as needed) */
    height: 240px; /* Adjust height as needed */
    font-weight: bold !important;
    padding: 10px; /* Optional: Add some padding for better appearance */
    border: none !important; /* Remove border */
    color: #fff !important; /* Text color */
    font-size: 19px; /* Font size */
    resize: none; /* Prevent resizing by user */
    line-height: 1.5; /* Adjust line-height for readability */
    overflow: hidden; /* Hide scrollbars */
    position: relative;
  }
  .m-t-15 {
    margin-top: 15px;
  }
  .our-client-heading {
    font-size: 38px !important;
  }
  .pad-t-70 {
    padding: 25px 0px 25px 0px !important;
  }
  .heading-color {
    font-size: 35px !important;
    text-align: center !important;
  }
  .para-size {
    text-align: center !important;
  }
  .align-left {
    text-align: center !important;
    padding: 0px 20px 30px 20px !important;
  }
  .process-heading {
    padding: 0px 20px 0px 20px !important;
    text-align: center !important;
    font-size: 35px !important;
  }
  .process-para {
    text-align: center !important;
    padding: 0px 20px !important;
  }
}

.free-consult-btn {
  font-family: "Roboto", Sans-serif;
  font-weight: 500;
  fill: #ffffff;
  color: #ffffff;
  background-color: #000000;
  border-radius: 50px 50px 50px 50px;
  padding: 10px 15px 10px 15px;
  cursor: pointer;
  font-size: 20px;
}
.free-consult-btn:hover {
  color: #ffffff;
  background-color: #ff7302;
  border-color: #ff7302;
}

.award-bg-width {
  width: 99%;
  display: flex;
  position: absolute;
}
.our-work-title {
  color: #f47a1f;
  font-family: Roboto, Sans-serif;
  font-weight: 600;
  font-size: 39px;
}
.our-work-title-2 {
  color: #000;
  font-family: Roboto, Sans-serif;
  font-weight: 600;
  font-size: 39px;
}
.our-work-bg-color {
  background-color: #071e42;
  text-align: left;
  padding: 60px;
}
.our-work-bg-color-2 {
  background-color: transparent;
  background-image: linear-gradient(180deg, #fff 0, #e6ab6e 100%);
  text-align: left;
  padding: 60px;
}
.our-work-para {
  color: #fff;
  font-family: Roboto, Sans-serif;
  font-size: 22px;
  font-weight: 400;
  line-height: 1.5em;
}
.our-work-para-2 {
  color: #000;
  font-family: Roboto, Sans-serif;
  font-size: 22px;
  font-weight: 400;
  line-height: 1.5em;
}
.free-consult-bg-width {
  width: 80%;
  display: flex;
  position: absolute;
}
.width-100 {
  width: 100%;
}
.width-60 {
  width: 60%;
}
.heading-color {
  color: #314252;
  font-family: "Roboto", Sans-serif;
  font-size: 40px;
  font-weight: 600;
  text-align: left;
}
.para-size {
  font-family: "Roboto", Sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.5em;
  text-align: left;
}
.our-client-heading {
  color: #314252;
  text-align: center;
  font-family: "Roboto", Sans-serif;
  font-size: 48px;
  font-weight: 600;
  line-height: 1em;
}
.review-btn {
  font-family: "Roboto", Sans-serif;
  font-weight: 500;
  font-size: 20px;
  fill: #ff7302;
  color: #ff7302;
  cursor: pointer;
  background-color: #00000000;
  border-style: solid;
  border-color: #ff7302;
  border-width: 3px 3px 3px 3px;
  border-radius: 50px 50px 50px 50px;
  padding: 10px 15px 10px 15px;
}
.review-btn:hover {
  color: #ffffff;
  background-color: #ff7302;
  border-color: #ff7302;
}
.cta-btn {
  font-family: "Roboto", Sans-serif;
  font-weight: 500;
  font-size: 20px;
  fill: #ff7302;
  cursor: pointer;
  color: #ffffff;
  background-color: #ff7302;
  border-color: #ff7302;
  border-style: solid;
  border-width: 3px 3px 3px 3px;
  border-radius: 10px;
  padding: 10px 15px 10px 15px;
  margin-bottom: 20px;
}
.mb-60 {
  margin-bottom: 60px;
}
.clients-img {
  border: 1px solid #c5c5c5;
}
.align-left {
  text-align: left;
  padding-left: 25px;
}
.justify-center {
  justify-content: center;
}
.flex-align-center {
  display: flex;
  align-items: center;
}
.slick-track {
  display: flex !important;
  align-items: flex-start !important;
}

.flex-center {
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.process-bg-blue {
  background-color: #314252;
}
.process-heading {
  color: #ffffff;
  font-family: "Roboto", Sans-serif;
  font-size: 48px;
  font-weight: 600;
  line-height: 1em;
  text-align: left;
}
.free-consult-heading {
  color: #000;
  font-family: "Roboto", Sans-serif;
  font-size: 39px;
  font-weight: 600;
  line-height: 1em;
  text-align: center;
  width: 70%;
  padding: 115px 0px;
}
.process-para {
  text-align: left;
  padding-left: 30px;
  color: #ffffff;
  font-family: "Roboto", Sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.3em;
}
.pad-t-70 {
  padding: 70px 0px 70px 0px;
}
.testimonial-box {
  border-style: solid;
  border-radius: 25px;
  border-width: 60px 1px 1px 1px;
  border-color: #f57223;
  margin: 0px 20px;
  padding: 0px 30px;
}
.testimonial-img {
  height: 75px;
  margin-top: -35px;
  width: 75px;
  border-radius: 100%;
}
.testimonial-name {
  text-align: left;
  font-size: 16px;
  font-weight: 600;
}
.testimonial-title {
  text-align: left;
  font-size: 12px;
  color: #8d8d8d;
  margin-top: -17px;
}
.testimonial-para {
  text-align: left;
  margin-top: 20px;
  font-size: 16px;
}
.testimonial-date {
  text-align: left;
  font-weight: 600;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
